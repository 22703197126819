import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';

function Documentacion() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_2.jpg")'
  };

  const icon1Img = {
    backgroundImage: 'url("/images/work-1_docs.jpg")'
  };

  const icon2Img = {
    backgroundImage: 'url("/images/work-3.jpg")'
  };



  return (  
<>

        <div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2>Documentación</h2>
					<span>Toda la documentación que necesitas respecto<br />al Open Memorial María Herranz Gómez</span>
				</div>
			</div>
		</div>

		<div id="fh5co-portfolio">
			<div className="container">

				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center heading-section animate-box">
						<h3>Documentos Recientes</h3>
					</div>
				</div>

				
				<div className="row row-bottom-padded-md">
					<div className="col-md-12">
						<ul id="fh5co-portfolio-list">



							
							<li className="one-third animate-box" data-animate-effect="fadeIn" style={icon1Img}> 
							<a href="/downloads/invitacion_20240718.pdf" target="blank" className="color-1">
								<div className="case-studies-summary">
									<span>Documento</span>
									<h2>Invitación a la Competición</h2>
								</div>
							</a>
						</li>

						<li className="one-third animate-box" data-animate-effect="fadeIn" style={icon2Img}> 
							<a href="/downloads/cartel_evento.pdf" target="blank" className="color-1">
								<div className="case-studies-summary">
									<span>Documento</span>
									<h2>Cartel del Evento</h2>
								</div>
							</a>
						</li>

		
						</ul>		
					</div>
				</div>



				
			</div>
		</div>



</>
  );
}

export default Documentacion;
