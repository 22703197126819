import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import Mapa from '../components/Mapa';
import { Stepper } from 'react-form-stepper';

function Llegar() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_llegar.jpg")'
  };


  return (  
<>


        <div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2>Cómo llegar</h2>
					<span></span>
				</div>
			</div>
		</div>


		<div >
			<div ></div>
			<div >
			<Stepper
				steps={[
					{ label: 'Estación de atocha madrid' }, 
					{ label: 'Step 2' }, 
					{ label: 'Step 3' },
					{ label: 'Step 3' },
					{ label: 'Step 3' },
					{ label: 'Step 3' },
					{ label: 'Step 3' },
				]}
				activeStep={3}
				/>
			</div>
		</div>




        <Mapa />
		





</>
  );
}

export default Llegar;
