import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';

function Politica() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_3.jpg")'
  };


  return (  
<>


    <div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2>Política de Privacidad</h2>
					{/* <span>Recordando su talento y su pasión por la gimnasia</span> */}
				</div>
			</div>
		</div>

    <div id="fh5co-about">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3>Política de privacidad</h3>
						<p>
						<strong>1. Introducción</strong>
                        <br />
En <strong>CT RUDITRAMP</strong>, respetamos tu privacidad y nos comprometemos a proteger tus datos personales. Esta Política de Privacidad describe cómo recopilamos, usamos, almacenamos y compartimos tu información cuando visitas nuestro sitio web [URL del sitio web] o utilizas nuestros servicios.
<br /><br />
<strong>2. Información que Recopilamos</strong>
<br />
Recopilamos la siguiente información:
<br />
Datos Personales: Nombre, dirección de correo electrónico, número de teléfono, dirección de residencia, etc.
<br />
Datos de Uso: Información sobre cómo utilizas nuestro sitio web y servicios, incluyendo dirección IP, tipo de navegador y páginas visitadas.
<br />
Datos de Transacción: Información relacionada con tus compras, reservas o cualquier otro tipo de transacción realizada a través de nuestro sitio.
<br /><br />
<strong>3. Cómo Utilizamos Tu Información</strong>
<br />
Utilizamos tu información para:
<br />
Proporcionar y mejorar nuestros servicios.<br />
Procesar tus transacciones y reservas.<br />
Enviarte actualizaciones, ofertas y promociones.<br />
Responder a tus consultas y solicitudes.<br />
Cumplir con nuestras obligaciones legales.
<br /><br />
<strong>4. Compartición de Información</strong>
<br />
Podemos compartir tu información con:
<br />
Autoridades Legales: Cuando sea necesario para cumplir con la ley, responder a procesos legales o proteger nuestros derechos.
<br /><br />
<strong>5. Seguridad</strong>
<br />
Implementamos medidas de seguridad razonables para proteger tus datos personales contra acceso no autorizado, divulgación, alteración o destrucción. Sin embargo, ninguna medida de seguridad es infalible, y no podemos garantizar una protección absoluta.
<br /><br />
<strong>6. Tus Derechos</strong>
<br />
Tienes derechos sobre tus datos personales, incluyendo:<br />
Acceso: Puedes solicitar una copia de la información que tenemos sobre ti.<br />
Rectificación: Puedes solicitar la corrección de datos inexactos o incompletos.<br />
Eliminación: Puedes solicitar que eliminemos tus datos personales, sujeto a ciertas excepciones.<br />
Oposición: Puedes oponerte al procesamiento de tus datos en ciertos casos.
<br /><br />
<strong>7. Cambios a Esta Política</strong>
<br />
Podemos actualizar esta Política de Privacidad ocasionalmente. Te notificaremos sobre cambios importantes mediante la publicación de la nueva política en nuestro sitio web y, si corresponde, a través de otros canales de comunicación.
<br /><br />
<strong>8. Contacto</strong>
<br />
Si tienes preguntas o preocupaciones sobre esta Política de Privacidad o sobre cómo manejamos tus datos personales, por favor contáctanos en:
<br /><br />
CT RUDITRAMP<br />
Calle Valdemona s/n<br />
Cabanillas del Campo<br />
19171 Guadalajara.<br />
Tel 652 67 16 14.<br />
Email ruditramp@gmail.com.<br />

						</p>
					</div>
				</div>
			</div>

		</div>
</>
  );
}

export default Politica;
