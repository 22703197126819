import React from 'react';
import { Link } from 'react-router-dom';



function Header(){
    return(
        <>
        		<div id="fh5co-header">
			<header id="fh5co-header-section">
				<div className="container">
					<div className="nav-header">
						<a href="#" className="js-fh5co-nav-toggle fh5co-nav-toggle"><i></i></a>
						<h1 id="fh5co-logo"><Link to="/"><img src="/images/logo-blanco.png" width="300" /></Link></h1>
						
						<nav id="fh5co-menu-wrap" role="navigation">
							<ul className="sf-menu" id="fh5co-primary-menu">
								<li>
									<Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
								</li>
								<li>
									<Link to="/memorial"><i className="fa-solid fa-hand-dots"></i> Memorial</Link>
								</li>
								<li>
									<Link to="/documentacion"><i className="fa-solid fa-folder"></i> Documentación</Link>
								</li>
								<li>
									<a href="#" className="fh5co-sub-down"><i className="fa-solid fa-circle-info"></i> Información <i className="fa-solid fa-caret-down"></i></a>
									<ul className="fh5co-sub-menu">
										{/* <li><Link to="/como-llegar">Cómo Llegar</Link></li> */}
										<li><Link to="/donde-alojarse">Dónde Alojarse</Link></li>
										<li><Link to="/donde-comer">Dónde Comer</Link></li>
									</ul>
								</li> 

								<li><Link to="/contacto"><i className="fa-solid fa-address-book"></i> Contacto</Link></li>
								{/* <li><Link to="/inscripcion"><i className="fa-solid fa-id-card"></i> Inscripción</Link></li> */}
							</ul>
						</nav>
					</div>
				</div>
			</header>
		</div>
        </>
    );
}

export default Header;