import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/styles.css'; // Importa tu archivo de estilos
import HotelList from '../components/HotelList';



function Alojamiento() {
    const [hotelList, setHotelList] = useState([]);
    const url = '/data/hoteles.json'; 

	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };

          const fetchHoteles = async () => {
            try {
              const response = await fetch(url+'?v='+Math.random());
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setHotelList(data);
              handleModuleLoad();
            } catch (error) {
              console.error('Fetch error:', error);
            }
          };


        fetchHoteles();
		
    

	//	return () => {};


	  }, [url]);

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_3_hotel.jpg")'
  };

  const icon1Img = {
    backgroundImage: 'url("/images/work-1_docs.jpg")'
  };



  return (  
<>




        <div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2>Dónde Alojarse</h2>
					<span>Ubicados cerca de nuestras instalaciones deportivas</span>
				</div>
			</div>
		</div>


        <div id="fh5co-portfolio">
			<div className="container">

				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center heading-section animate-box">
						<h3>Alojamientos</h3>
						<p>Nuestra selección de alojamientos ofrece opciones cómodas y convenientes para todos los presupuestos, desde hoteles de lujo hasta acogedores alojamientos rurales.</p>
					</div>
				</div>

				
				<div className="row row-bottom-padded-md">
					<div className="col-md-12">
              <HotelList hotelList={hotelList} />
					</div>
				</div>


				
			</div>
		</div>




</>
  );
}

export default Alojamiento;
