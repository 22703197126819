import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';

function Memorial() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_3.jpg")'
  };


  return (  
<>


    <div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2><strong>Acerca</strong> del memorial</h2>
					<span>Recordando su talento y su pasión por la gimnasia</span>
				</div>
			</div>
		</div>

    <div id="fh5co-about">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section animate-box">
						<h3>Como empezamos</h3>
						<p>
							Al unirnos para planificar este memorial, nos guió el deseo de crear un espacio donde su memoria perdure, compartiendo historias y recuerdos que mantendrán vivo su recuerdo.
						</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row row-bottom-padded-md">
					<div className="col-md-6 animate-box">
						<figure>
							<img src="/images/about-image.jpg" alt="Free HTML5 Bootstrap Template by FREEHTML5.co" className="img-responsive" />
						</figure>
					</div>
					<div className="col-md-6 animate-box">
						<h3>Comienzo Simple y Humilde</h3>
						<p>
							Fue evidente para todos nosotros que un memorial sería la manera más apropiada de recordar su talento excepcional y la huella indeleble que dejó en nuestras vidas y en el mundo de la gimnasia.
							<br /><br />
							Nos conmovió profundamente la partida de nuestra querida María, impulsándonos a crear este memorial como un tributo sincero a su vida.
						</p>
					</div>
				</div>


				<div className="row row-bottom-padded-md">

					<div className="col-md-6 animate-box">
						<h3>Asociación Española contra la Meningitis</h3>

						<p>
							{/* Desde que esta terrible enfermedad decidió quitarnos a María nos hemos propuesto que algún dia nadie más tenga que pasar por nuestra situación.
							<br />
							<br />
							
							Para este fin y con la idea de erradicar la enfermedad, vamos a colaborar decididamente con la <a href="https://contralameningitis.org" target="_blank" className="texto_underline">Asocioación Española contra la Meningitis</a>, para esta misión necesitamos tu colaboración. */}

							Tras la dolorosa perdida de nuestra querida María, a consecuencia de una meningitis el pasado mes de abril, el C.T. Ruditramp desea organizar un evento deportivo de Gimnasia Trampolín que lleve su nombre y así hacerle el homenaje que se merece.
							<br /><br />
							El evento tendrá un carácter solidario a beneficio de la <a href="https://contralameningitis.org" target="_blank" className="texto_underline">Asociación Española contra la Meningitis (AECM)</a> que investiga para la prevención y detección precoz de esta enfermedad
						</p>
						<br />
						<div className="row">
														<div className="col-md-6">
								<figure>
								<a href="https://contralameningitis.org" target="_blank">
								<img src="/images/logo-men.png" alt="Asociación Española contra la Meningitis" width="250" />
								</a>
								</figure>
							</div>
							<div className="col-md-6">
							<p><a className="btn btn-primary" href="https://contralameningitis.org/producto/donaciones/" target="_blank">Ir a la Asociación</a></p>
							</div>

						</div>
					</div>
					<div className="col-md-6 animate-box">
						<figure>
							<img src="/images/about-image-men.jpg" alt="Asociación Española contra la Meningitis" className="img-responsive" />
						</figure>
					</div>
				</div>
			</div>
		</div>
</>
  );
}

export default Memorial;
