import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//components
import Footer from './components/Footer';
import Header from './components/Header';
import Patrocinadores from './components/Patrocinadores';


//pages
import Home from './pages/Home';
import Memorial from './pages/Memorial';
import Documentacion from './pages/Documentacion';
import Alojamiento from './pages/Alojamiento';
import Contacto from './pages/Contacto';
import Llegar from './pages/Llegar';
import Comer from './pages/Comer';
import Inscripcion from './pages/Inscripcion';
import Politica from './pages/Politica';
import Penalizacion from './pages/Politica';

import CookieConsent, { Cookies } from "react-cookie-consent";

//https://medium.com/globant/react-state-management-b0c81e0cbbf3

function App() {
  return (  
      <Router>
        <div id="fh5co-wrapper">
        <div id="fh5co-page">
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/memorial" element={<Memorial />} />
            <Route path="/documentacion" element={<Documentacion />} />
            <Route path="/donde-alojarse" element={<Alojamiento />} />
            <Route path="/donde-comer" element={<Comer />} />
            <Route path="/como-llegar" element={<Llegar />} />
            <Route path="/contacto" element={<Contacto />} />
            {/* <Route path="/inscripcion" element={<Inscripcion />} /> */}
            <Route path="/politica-privacidad" element={<Politica />} />
       

          </Routes>
        </div>
        <>
        {/* <CookieConsent
  location="bottom"
  buttonText="Aceptar Cookies"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio. <a href="">Ver política de Cookies</a>.
</CookieConsent> */}
        </>
      </div>
      <Patrocinadores />
      <Footer />
    </Router>
  );
}

export default App;
