import React from 'react';

const HotelList = ({ hotelList }) => {
    return (
        <ul id="fh5co-portfolio-list">
            {hotelList.map((hotel, index) => (
                <li 
                    className="one-third" 
                    style={{ backgroundImage: `url(/images/hoteles/${hotel.img})` }} 
                    key={index}
                >
                    <a href="#" className={`color-${index}`}>
                        <div className="case-studies-summary">
                            <span>{hotel.tipo}</span>
                            <h2>{hotel.nombre}</h2>
                            <span className="hotel-direc">{hotel.direccion}</span><br />
                            <span>{hotel.poblacion} - {hotel.telefono}</span>
                        </div>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default HotelList;