import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import Swal from "sweetalert2";

import '../css/styles.css';

//https://dev.to/vyan/mastering-nextjs-the-ultimate-guide-to-structuring-large-scale-projects-in-2024-h4e

const SignupSchema = Yup.object().shape({
    tipo:         Yup.string().required('Dato obligatorio'),
    club:         Yup.string().required('Dato obligatorio'),
    clubPhone:    Yup.string().required('Dato obligatorio'),
    email:        Yup.string().email('Email no válido').required('Dato obligatorio'),
    license:      Yup.string().required('Dato obligatorio'),
    name:         Yup.string().required('Dato obligatorio'),
    firstName:    Yup.string().required('Dato obligatorio'),
    lastName:     Yup.string().required('Dato obligatorio'),
    gender:       Yup.string().required('Dato obligatorio'),
    category:     Yup.string().required('Dato obligatorio'),
    category: Yup.string().when('tipo', {
        is: (value) =>  value === 'gimnasta',
        then: () => Yup.string().required('Dato obligatorio'),
        otherwise: () =>  Yup.string().notRequired()
      }),
    sport: Yup.array().when('tipo', {
        is: (value) =>  value === 'gimnasta',
        then: () => Yup.array()
          .min(1, 'Debes seleccionar al menos uno')
          .required('Dato obligatorio'),
        otherwise: () => Yup.array().notRequired() 
      }),
    acceptedTerms: Yup.boolean().oneOf([true], 'Tienes que aceptar los términos y condiciones').required('Dato obligatorio'),
    
  });


  



function FormInscripcion() {


    const [swalProps, setSwalProps] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <>

        <br />
        <br />
  


                <Formik
                    initialValues={{
                        tipo:'gimnasta',
                        club:'',
                        clubPhone:'',
                        email:'',
                        license:'',
                        name:'',
                        firstName:'',
                        lastName :'',
                        category:'',
                        gender:'',
                        sport:[],
                        acceptedTerms:false
                    }}
                    validationSchema={SignupSchema}
                    // onSubmit={async (values) => {
                    //     //await new Promise((r) => setTimeout(r, 500));
                    //     alert(JSON.stringify(values, null, 2));
                    // }}
                    onSubmit={async (values, { setSubmitting,resetForm }) => {
                        try {
                        //   const response = await fetch('http://localhost:4000/api/inscripciones', {
                        //     method: 'POST',
                        //     headers: {
                        //       'Content-Type': 'application/json',
                        //     },
                        //     body: JSON.stringify(values),
                        //   });

      

                        setIsSubmitting(true);


                          const response = await fetch('https://memorialmariahg.org/api/inscripciones', {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(values),
                          });
                
                          const data = await response.json();
                         // console.log('Respuesta del servidor:', data);

                          Swal.fire("Gracias!", "Hemos recibido tu solicitud, el id de la solicitud es: <strong>"+data.id+"</strong> guarda este localizador para cualquier consulta o modificación", "success");

                          resetForm()
                            setIsSubmitting(false);
    
                
                          // Puedes manejar la respuesta del servidor aquí (mostrar un mensaje de éxito, redirigir, etc.)
                        } catch (error) {
                          console.error('Error al enviar el formulario:', error);
                          // Manejo de errores
                        } finally {
                          setSubmitting(false); // Finaliza el estado de envío
                          setIsSubmitting(false);

                        }
                      }}
                    >
                        {({ values }) => (
                    <Form>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12">
                      <p>Este formulario de inscripción estará <strong>disponible desde el 2 de Septiembre hasta el 20 de Septiembre de 2024</strong>. Por favor, asegúrate de completar y enviar tu inscripción dentro de este período para ser considerado. Las solicitudes recibidas fuera de estas fechas no serán aceptadas. Si tienes alguna duda, comunícate con nosotros antes de la fecha límite en <a href="mailto:administracion@memorialmariahg.org">administracion@memorialmariahg.org</a>.<br />
                      <br />
                      La inscripción es gratuita pero se propone <a href="https://contralameningitis.org/producto/donaciones/" target='_blank'>hacer un donativo de una cantidad voluntaria</a> a la Asociación Española contra la Meningitis (AEM).
                      <br />
                      <br />
                      
                      <a href="https://contralameningitis.org/producto/donaciones/" target='_blank'><img src="/images/logo-men.png" width={200} /></a>
                      <br />
                      <br />
                      <strong>¡No pierdas esta oportunidad y regístrate a tiempo!</strong></p>
                      <br /><br />
                        </div>


                        <div className="col-md-4">
                                <div className="form-group">
                                 <label htmlFor="tipo">Tipo de Inscripción</label>
                                 <Field as="select" name="tipo" id="tipo" className="form-control">
                                    <option value="" label="Selecciona Tipo">Selecciona Categoría</option>
                                    <option value="gimnasta" label="gimnasta">Gimnasta</option>
                                    <option value="entrenador" label="entrenador">Entrenador</option>
                                    <option value="delegado" label="delegado">Delegado</option>

                                </Field>

                                <ErrorMessage name="tipo">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>

                                </div>
                            </div>

     
                        
                            <div className="col-md-4">
                                <div className="form-group">
                                 <label htmlFor="club">Club</label>
                                 <Field id="club" name="club" placeholder="Club" className="form-control" />
                                 
                                 <ErrorMessage name="club">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                <label htmlFor="clubPhone">Teléfono del club</label>
                                <Field id="clubPhone" name="clubPhone" placeholder="Teléfono del club" className="form-control" />
                                
                                <ErrorMessage name="clubPhone">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="email">Email</label>
                        <Field id="email" name="email" placeholder="Email" className="form-control" />
                        
                        <ErrorMessage name="email">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="license">Nº de Licencia</label>
                        <Field id="license" name="license" placeholder="Nº de Licencia" className="form-control" />
                        
                        <ErrorMessage name="license">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                        <div className="col-md-3">
                                <div className="form-group">
                                <label htmlFor="gender">Género</label>
                        
                        <Field as="select" name="gender" id="gender" className="form-control">
                            <option value="" label="Selecciona Género">Selecciona Género</option>
                            <option value="masculino" label="masculino">masculino</option>
                            <option value="femenino" label="femenino">femenino</option>

                        </Field>
                        
                        <ErrorMessage name="gender">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="form-group">
                                <label htmlFor="name">Nombre</label>
                                <Field id="name" name="name" placeholder="Nombre" className="form-control" />
                                
                                <ErrorMessage name="name">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                <label htmlFor="firstName">Primer Apellido</label>
                        <Field id="firstName" name="firstName" placeholder="Primer Apellido" className="form-control" />
                        
                        <ErrorMessage name="firstName">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">

                                <label htmlFor="lastName">Segundo Apellido</label>
                        <Field id="lastName" name="lastName" placeholder="Segundo Apellido" className="form-control" />
                        
                        <ErrorMessage name="lastName">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">

                                <label htmlFor="category">Categoría</label>
                        
                        <Field as="select" name="category" id="category" className="form-control" disabled={values.tipo != 'gimnasta'}>
                            <option value="" label="Selecciona Categoría">Selecciona Categoría</option>
                            <option value="Base A" label="Base A 7-10 años">Base A 7-10 años</option>
                            <option value="Base B" label="Base B 11-16 años">Base B 11-16 años</option>
                            <option value="Elite C" label="Élite C sub doce años">Élite C sub doce años</option>
                            <option value="Elite D " label="Élite D 13-16 años">Élite D 13-16 años</option>
                            <option value="Elite E" label="Élite E 17 en adelante">Élite E 17 en adelante</option>
                        </Field>
                    
                    <ErrorMessage name="category">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                            
                            <div className="col-md-4">
                                <div className="form-group">

                                <label htmlFor="sport">Modalidad <span className="small-info">(Puedes seleccionar varios pulsando CTRL)</span></label>
                        
                        <Field as="select" name="sport" id="sport" multiple={true} className="form-control" disabled={values.tipo != 'gimnasta'}>
                            <option value="trp" label="trampolín">Trampolín</option>
                            <option value="dmt" label="doble minitramp">Doble minitramp</option>
                            <option value="sin" label="sincronismo">Sincronismo</option>

                        </Field>
                        <ErrorMessage name="sport">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
									<div className="form-group">
                                        <br />
                                    
                                        <label>
                                            <Field type="checkbox" name="acceptedTerms" id="acceptedTerms" /> He leído y acepto la <a href="/politica-privacidad">politica de privacidad</a>
                                        </label>

                                        <ErrorMessage name="acceptedTerms">
                            {msg => <div className="error-wrapper"><span className="error-message">{msg}</span></div>}
                        </ErrorMessage>
                                        
									</div>
								</div>
                        <div className="col-md-12">
									<div className="form-group">
                                        <br />
                                        <br />
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            {isSubmitting ? 'Enviando...' : 'Enviar Inscripción'}
                                        </button>
									</div>
								</div>
                    </div>
                    <br/>
                    <br/>
                    </Form>
                     )}
                </Formik>

        </>
    );
}

export default FormInscripcion;