function Patrocinadores(){
    return(
        <>
            <div className="container patrocinadores-container">

            <div className="patrocinadores">
            <div className="header">
                <h2>Organizan</h2>
            </div>
            <ul>
                <li><img src="/images/logos/organiza/logo1.png" /></li>
                <li><img src="/images/logos/organiza/logo2.png" /></li>
                
            </ul>
        </div>
        <div className="patrocinadores">
            <div className="header">
                <h2>Colaboran</h2>
            </div>
            <ul>
                <li><img src="/images/logos/patrocina/logo1.png" /></li>
                <li><img src="/images/logos/patrocina/logo2.png" /></li>
                <li><img src="/images/logos/patrocina/logo3.png" /></li>
                <li><img src="/images/logos/patrocina/logo4.png" /></li>
                <li><img src="/images/logos/patrocina/logo5.png" /></li>
                <li><img src="/images/logos/colabora/logo22.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo34.png?v=1" /></li>
                <li><img src="/images/logos/patrocina/logo6.png?v=1" /></li>
            </ul>
        </div>
        <div className="patrocinadores">
            <div className="header">
                <h2>Patrocinan</h2>
            </div>
            <ul className="cola">
                <li><img src="/images/logos/colabora/logo1.png" /></li>
                <li><img src="/images/logos/colabora/logo2.png" /></li>
                <li><img src="/images/logos/colabora/logo3.png" /></li>
                <li><img src="/images/logos/colabora/logo4.png" /></li>
                <li><img src="/images/logos/colabora/logo5.png" /></li>
                <li><img src="/images/logos/colabora/logo6.png" /></li>
                <li><img src="/images/logos/colabora/logo7.png" /></li>
    
                <li><img src="/images/logos/colabora/logo9.png" /></li>
                <li><img src="/images/logos/colabora/logo10.png" /></li>
                <li><img src="/images/logos/colabora/logo11.png" /></li>
                <li><img src="/images/logos/colabora/logo12.png" /></li>
                {/* <li><img src="/images/logos/colabora/logo13.png" /></li> */}
                <li><img src="/images/logos/colabora/logo14.png?v=1" /></li>

                <li><img src="/images/logos/colabora/logo16.png?v=1" /></li>
                
                <li><img src="/images/logos/colabora/logo18.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo19.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo20.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo23.png?v=1" /></li>
                {/* <li><img src="/images/logos/colabora/logo21.png?v=1" /></li> */}
                <li><img src="/images/logos/colabora/logo15.png?v=1" /></li>
                
       
                <li><img src="/images/logos/colabora/logo24.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo25.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo26.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo27.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo28.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo29.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo30.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo31.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo32.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo33.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo35.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo36.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo37.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo38.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo39.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo40.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo41.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo42.png?v=1" /></li>

                <li><img src="/images/logos/colabora/logo43.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo44.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo45.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo46.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo47.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo48.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo49.png?v=1" /></li>
                <li><img src="/images/logos/colabora/logo50.png?v=1" /></li>




            </ul>
        </div>


            </div>
        </>
    )   
}

export default Patrocinadores;