import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import {APIProvider, Map, Marker, AdvancedMarker , Pin} from '@vis.gl/react-google-maps';

import Mapa from '../components/Mapa';


function Contacto() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
			
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/cover_bg_2.jpg")'
  };


  return (  
<>


		<div className="fh5co-hero fh5co-hero-2">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover fh5co-cover_2 text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2>Contacto</h2>
					<span>Contactanos para cualquier duda y tan pronto<br />como podamos nos pondremos en contacto contigo</span>
					
				</div>
			</div>
		</div>

		
		<div id="fh5co-contact" className="animate-box">
			<div className="container">
				<form action="#">
					<div className="row">
						<div className="col-md-6">
							<h3 className="section-title">Acceso</h3>
							<p>Con amplios estacionamientos y acceso conveniente al transporte público, nuestra ubicación es ideal para atletas y familias que buscan comodidad y excelencia deportiva.</p>
			
						</div>
						<div className="col-md-6">
							<div className="row">
							<div className="col-md-12">
							<h3 className="section-title">Contacto</h3>
							<p>Contacta con nosotros para cualquier duda o aclaración, nos pondremos en contacto lo antes posible.</p>
							<ul className="contact-info">
								<li><i className="fa-solid fa-location-pin"></i> Cabanillas del Campo 19171<br />Guadalajara</li>
								{/* <li><i className="fa-solid fa-phone"></i> 949 33 24 30</li> */}
								<li><i className="fa-regular fa-envelope"></i> <a href="mailto:administracion@memorialmariahg.org">administracion@memorialmariahg.org</a></li>
								<li><i className="fa-solid fa-globe"></i><a href="#">www.memorialmariahg.org</a></li>
							</ul>

						</div>
								{/* <div className="col-md-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Nombre" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Email" />
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="" className="form-control" id="" cols="30" rows="7" placeholder="Mensaje"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<input type="submit" value="Enviar" className="btn btn-primary" />
									</div>
								</div>  */}
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>


		{/* <Mapa /> */}





</>
  );
}

export default Contacto;
