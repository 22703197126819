function Footer(){
    return(
        <>
        		<footer>
			<div id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
							<h3>Acerca de Nosotros</h3>
							<p>Al unirnos para planificar este memorial, nos guió el deseo de crear un espacio donde su memoria perdure, compartiendo historias y recuerdos que mantendrán vivo su recuerdo.</p>
						</div>
						<div className="col-md-6 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
	

						</div>

						<div className="col-md-2 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
							<h3>Siguenos</h3>
							<ul className="fh5co-social">
								{/* <li><a href="#"><i className="fa-brands fa-twitter"></i> Twitter</a></li> */}
								<li><a href="https://www.instagram.com/openmemorial.mariahg?igsh=MTJkY2E5dmZtb3QzZw%3D%3D&utm_source=qr" target="_blank"><i className="fa-brands fa-instagram"></i> Instagram</a></li>
								<li><a href="#"><i className="fa-brands fa-facebook"></i> Facebook</a></li>

								
							</ul>
						</div>
					</div>
				</div>
				<div className="fh5co-copyright text-center">
					<p className="footer-maria">Made with all our love for María, always in our hearts <img src="/images/heart.png" width="20" height="20" /></p>	
				</div>
			</div>
		</footer>
        </>
    )   
}

export default Footer;