import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';

function Home() {


	useEffect(() => {
		// Esta función se ejecutará cuando se monte el componente
		const handleModuleLoad = () => {
			window.mainMenu();
			window.parallax();
			window.offcanvas();
			window.mobileMenuOutsideClick();
			window.contentWayPoint();
		  };
	
		handleModuleLoad();

		return () => {};


	  }, []); // El array vacío [] asegura que esto se ejecute solo una vez al montar

	  



  const heroImage = {
    backgroundImage: 'url("/images/fondo_web.png")'
  };


  const work1Image = {
    backgroundImage: 'url("/images/work-1.jpg?v=1")'
  };

  const work2Image = {
    backgroundImage: 'url("/images/work-2.jpg?v=1")'
  };


  return (  
<>


		
		<div className="fh5co-hero">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover text-center" data-stellar-background-ratio="0.5" style={heroImage}>
				<div className="desc animate-box">
					<h2 className="quicksand-bold">Open <strong>Memorial</strong><br />María Herranz Gómez</h2>
					<span>Cabanillas del Campo 26 y 27 de Octubre de 2024</span><br />
					
					<p><Link className="btn btn-primary btn-lg btn-learn" to="/memorial">Saber más</Link> 
					{/* <Link className="btn btn-primary btn-lg btn-learn" to="/inscripcion">Inscripciones</Link>  */}
					</p>
					<p className="colabora">Evento solidario a beneficio de: <img src="/images/logo-meni-white.png" width="220" /></p>
				</div>
			</div>
		</div>
		
		<div id="fh5co-partner">
			<div className="container">
				<div className="partner-wrap">
					<div className="wrap">
						<div className="partner animate-box">
							<div className="inner">
								<img className="img-responsive" src="images/logo01.png?v=1" alt="" />
							</div>
						</div>

						<div className="partner animate-box">
							<div className="inner">
								<img className="img-responsive" src="images/logo03.png?v=1" alt="" />
							</div>
						</div>
						<div className="partner animate-box">
							<div className="inner">
								<img className="img-responsive" src="images/logo04.png" alt="" />
							</div>
						</div>
						<div className="partner animate-box">
							<div className="inner">
								<img className="img-responsive" src="images/logo05_rudi.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="fh5co-work">
			<div className="container">
				<div className="row">
					<div className="col-md-12 work">
						<div className="row">
							<div className="col-md-6 col-md-pull-2">
								<div className="half-inner" style={work1Image}>
								</div>
							</div>
							<div className="col-md-6 animate-box">
								<div className="desc">
									<h3><a href="#">Información importante</a></h3>
									<p>
										Nuestra sección de descargas de documentación ofrece acceso directo a información crucial y detallada.
<br /><br />
									Documentos disponibles para ofrecer claridad a todos los participantes, favoreciendo un entendimiento completo de las normativas y procedimientos.

									</p>
									<p><a className="btn btn-primary" href="/documentacion">Ver más</a></p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 work">
						<div className="row">
							<div className="col-md-6 col-md-push-6">
								<div className="half-inner half-inner2" style={work2Image}>
								</div>
							</div>
							<div className="col-md-6 col-md-pull-6 animate-box">
								<div className="desc desc2">
									<h3><a href="#">C.T. Ruditramp</a></h3>
									<p>Nuestro club de gimnasia trampolín ofrece una emocionante oportunidad para aprender y dominar las habilidades de este deporte.</p>
									<p><a className="btn btn-primary" href="#">Saber más</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="fh5co-features">
				<div className="container">
					<div className="row">
						<div className="col-md-4 animate-box">

							<div className="feature-left">
								<span className="icon">
								<i className="fa-regular fa-compass"></i>
								</span>
								<div className="feature-copy">
									<h3>Desarrollo de habilidades y valores</h3>
									<p>Nos enfocamos en el desarrollo integral de los miembros del club.</p>
								</div>
							</div>

						</div>

						<div className="col-md-4 animate-box">
							<div className="feature-left">
								<span className="icon">
								<i className="fa-solid fa-heart"></i>
								</span>
								<div className="feature-copy">
									<h3>Auto superación y mejora continua</h3>
									<p>Fomentamos el desarrollo personal mediante la práctica regular y el aprendizaje de nuevas habilidades.</p>
								</div>
							</div>

						</div>
						<div className="col-md-4 animate-box">
							<div className="feature-left">
								<span className="icon">
								<i className="fa-regular fa-face-smile"></i>
								</span>
								<div className="feature-copy">
									<h3>Inclusión y diversidad</h3>
									<p>Estamos creando un espacios inclusivo donde se promueve la diversidad.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>




	


</>
  );
}

export default Home;
